<script setup>
import {Content} from '@/Layouts';
import {Head} from '@inertiajs/vue3';
import {useForm} from 'laravel-precognition-vue-inertia';
import {__} from "@/Utils";
import {Button, TextLink} from "@/Components/Common";
import {Checkbox, Form, Heading, Input} from "@/Components/Form";
import {route} from "ziggy-js";
import {H2} from "@/Components/Common/index.js";
import Socialite from "@/Pages/Auth/Components/Socialite.vue";
import CloudflareTurnstileModal from "@/Components/Form/CloudflareTurnstileModal.vue";
import {computed, ref} from "vue";

defineProps({
    status: {
        type: String,
    },
    enable_test_accounts: {
        type: Boolean,
        default: false
    }
});

const form = useForm('post', route('login'), {
    email: '',
    password: '',
    remember: false,
    cf_turnstile_token: '',
});
const cloudflareTurnstile = ref(null);

const submit = () => {
    form.transform(data => ({
        ...data,
        'cf-turnstile-response': data.cf_turnstile_token
    })).submit({
        onFinish: () => form.reset('password'),
    });
};

defineOptions({layout: Content})

const cloudflareTurnstileEnabled = computed(() => cloudflareTurnstile.value?.hasSiteKey)
</script>

<template>
    <Head :title="__('Log in')"/>

    <div class="mx-auto max-w-xl my-20 px-4 md:px-0">

        <Heading class="text-center">
            <template #title>
                <H2>{{ __('Log in') }}</H2>
            </template>
            <template #status>{{ status }}</template>
        </Heading>


        <Form :form="form" @submit.prevent="submit">
            <Input
                id="email"
                :label="__('Email')"
                :form="form"
                required
            />

            <Input
                id="password"
                type="password"
                :label="__('Password')"
                :form="form"
                required
            />

            <div class="flex items-center justify-between mt-4">
                <Checkbox
                    id="remember"
                    :label="__('Remember me')"
                    :form="form"
                />

                <div class="mt-4">
                    <TextLink :href="route('password.request')">
                        {{ __('Forgot your password?') }}
                    </TextLink>
                </div>
            </div>

            <CloudflareTurnstileModal :form ref="cloudflareTurnstile" v-model="form.cf_turnstile_token"/>

            <Button
                :disabled="!form.cf_turnstile_token && cloudflareTurnstile?.hasSiteKey"
                :loading="form.processing"
                class="w-full justify-center">
                {{ __('Log in') }}
            </Button>

        </Form>

        <Socialite class="mt-4"/>

        <div class="mt-4 text-center">
            {{ __("Don't have an account?") }}

            <TextLink :href="route('register')">
                {{ __('Sign up here') }}
            </TextLink>
        </div>

    </div>
</template>
